import React from 'react'
import { BlogLayout } from '../templates/BlogLayout';
export default () => (
  <BlogLayout>
    <h1>About me</h1>
    <h2>Why I have a personal blog</h2>
    <ul> <b>Reasons are</b>
      <li>I want to put my ideas out there so people can rebut them</li>
      <li>I want people who work with me to understand my thought process better</li>
      <li>I want people who are considering working with me to have a preview in my thought process</li>
      <li>I want to yell in the abyss and see if someone answers back (i.e. have interesting conversations)</li>
      <li>I want to write more prose</li>
      <li>I like building new stuff</li>
    </ul>
    <h2>Interests</h2>
    <ul> <b>Essentially</b>
      <li>building new stuff</li>
      <li>Learning new stuff</li>
    </ul>
    <h2>The facts</h2>
    <ul> <b>Work experience</b>
     <li> Technical co-founder @ <a href="https://www.awellhealth.com">Awell Health</a></li>
     <li> before that a consultant @ <a href="https://www.mckinsey.com">McKinsey </a></li>
     <li> before that worked in a CTO'ish role @ <a href="https://www.fonteynethekitchen.be">the family business</a></li>
    </ul>
    <ul> <b>Education</b>
      <li>MSc economics @ LSE</li>
      <li>MEng applied math @ UCL</li>
      <li>Diplome grande ecole (or something to that effect) @ ECP</li>
      <li>Bachelor in electrical engineering (or something to that effect) @ VUB</li>
      <li>Bachelor in business engineering @ VUB </li>
    </ul>
  </BlogLayout>
)